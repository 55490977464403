import Image from 'next/image'
import { memo, useEffect, useState, useCallback } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import Button from '../../components/Auth/Button'
import { ChevronRight, NoImage } from '../../components/Icons'
import { db_glossary } from '../../firebase'
import styles from './_explore.module.sass'
import commons from '../../components/Word/_commons.module.sass'
import convertNumberToShortString from '../../functions/convertNumberToShortString'
import Link from 'next/link'
import glossaryAtom from '../../atoms/glossary/glossaryAtom'
import displayAtom from '../../atoms/displayAtom'
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import Empty from '../../components/Empty'
import book from '../../public/images/book.webp'
import modalAtom from '../../atoms/modalAtom'
import ultimateAtom from '../../atoms/ultimateAtom'

const GetGlossaryData = memo(() => {
  const [glossary, setGlossary] = useRecoilState(glossaryAtom)
  const getGlossaryData = useCallback(async () => {
    const collections = (
      await getDocs(
        query(
          collection(db_glossary, "collections"),
          orderBy("order", "asc"),
          limit(6)
        )
      )
    ).docs.map((e) => {
      return {
        collection: e.id,
        subcollection_count: e.data().subcollection_count
      };
    })
    const subcollections = (
      await getDocs(
        query(
          collection(db_glossary, "subcollections"),
          where("collection", "in", collections.map(e=>e.collection)),
          where("set", "==", 1)
        )
      )
    ).docs.map((e) => {
      return {
        collection: e.data().collection,
        subcollection: e.id,
        word_count: e.data().word_count,
        image: e.data().image
      };
    })
    // console.log({collections, subcollections})
    return { collections, subcollections }
  }, [])
  useEffect(() => {
    if(!glossary){
      getGlossaryData().then(data=>setGlossary(data))
    }
  }, [])
  const setModal = useSetRecoilState(modalAtom)
  useEffect(()=>{
    setModal(false)
  }, [])
  return null
})

const Explore = memo(() => {
  
  const GlossaryCard = memo(({collection, subcollection, word_count, image}) => {

    const [imageError, setImageError] = useState(false)

    return (
      <Link href={`/app/glossary/${collection}/${subcollection}`} className={styles.glossaryCard}>
        <div className={styles.image}>
          <div className={commons.solidSkeleton} />
          {!imageError&&image?<Image onError={()=>setImageError(true)} src={image} alt='' fill />:imageError?<NoImage />:null}
          {word_count?
          <div className={styles.wordsNum}>
            {convertNumberToShortString(word_count)} <span>{word_count===1?'word':'words'}</span>
          </div>
          :<div className={`${commons.solidSkeleton} ${styles.wordsNum} ${styles.wordsNumSkeleton}`} style={{height: 38, width: 80}} />}
        </div>
        <div className={styles.content}>
          <div className={styles.name}>{subcollection}</div>
        </div>
      </Link>
    )
    
  })

  const GlossaryCategory = memo(({index, subcollection_count, collection}) => {

    const [glossary] = useRecoilState(glossaryAtom)

    const ArrowRight = memo(() => {
      return (
        <div className={styles.arrowRight}>
          {subcollection_count?<div className={styles.text}>{convertNumberToShortString(subcollection_count)} {subcollection_count>1?'collections':'collection'}</div>:null}
          <ChevronRight />
        </div>
      )
    })

    const GlossardCardContent = memo(()=>{
      let skeletonCardsToMap = [0,1,2,3,4,5]
      if(glossary.subcollections){
        return glossary.subcollections.filter(e=>e.collection===collection).map((item, key)=><GlossaryCard key={key} {...item} />)
      }else{
        return skeletonCardsToMap.map((e)=><CardSkeleton key={e} />)
      }
    })

    return (
      <div className={styles.glossaryCategory}>
        <Button text={collection} svg={<ArrowRight />} textStyle={{fontSize: '18px', color: 'var(--text)', textTransform: 'capitalize'}} path={`/app/glossary`} style={{width: '100%', textAlign: 'left', justifyContent: 'flex-start', boxShadow: 'none'}} colors={['var(--background)', 'var(--background)']} />
        <div className={styles.glossaryList}>
          <GlossardCardContent />
        </div>
      </div>
    )
  })

  const CardSkeleton = memo(() => {
    return (
      <div className={styles.glossaryCard}>
        <div className={`${commons.solidSkeleton} ${styles.image}`}>
        <div className={`${commons.solidSkeleton} ${styles.wordsNum} ${styles.wordsNumSkeleton}`} style={{height: 38, width: 80}} />
        </div>
        <div className={styles.content}>
          <div className={`${styles.name} ${commons.defSkeleton}`} style={{lineHeight: 1, marginBottom: 0, width: '80%'}}>-</div>
        </div>
      </div>
    )
  })

  const CategorySkeleton = memo(({collections}) => {

    let numberOfCollections = [0]
    
    if(collections){
      numberOfCollections = Array.from(Array(collections).keys())
    }

    return (
      <div className={`${commons.card}`} style={{padding: 0, paddingTop: 12}}>
        {numberOfCollections.map(e=>{
          return (
            <div key={e} className={commons.quickDefSkelCard} style={e>0?{marginTop: 24}:null}>
              <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 12px'}}>
                <div className={commons.defSkeleton} style={{width: 120, marginBottom: 12}}>-</div>
                <div style={{display: 'flex'}}>
                  <div className={commons.defSkeleton} style={{width: 84}}>-</div>
                  <div className={commons.defSkeleton} style={{width: 32, marginLeft: 12}}>-</div>
                </div>
              </div>
              <div className={styles.glossaryList} style={{width: '100%', marginTop: 12}}>
                <CardSkeleton /><CardSkeleton /><CardSkeleton /><CardSkeleton /><CardSkeleton /><CardSkeleton />
              </div>
            </div>
          )
        })}
      </div>
    )
  })

  const CategorySkeletonPage = memo(() => {
    const [display] = useRecoilState(displayAtom)
    return (
      <div className={styles.collectionSkeletonPage}>
        <CategorySkeleton collections={Math.round(display.height/400)} />
      </div>
    )
  })
  
  const Collections = memo(() => {

    const [glossary] = useRecoilState(glossaryAtom)
    
    return (
      <div className={styles.wordLists}>
        {
          glossary&&glossary.collections&&glossary.collections.length>0?
          glossary.collections.map((item, key)=><GlossaryCategory key={key} index={key} {...item} />)
          :
          glossary&&glossary.collections&&glossary.collections.length === 0 ? <Empty img={book} text='Search for any word' />
          : 
          <CategorySkeletonPage />
        }
      </div>
    )

  })

  const [ultimate] = useRecoilState(ultimateAtom)

  return (
    <div className={styles.explore}>
      {ultimate?<Collections />:<Empty img={book} text='Search for any word' />}
      {ultimate?<GetGlossaryData />:null}
    </div>
  )
})

export default Explore
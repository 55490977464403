const convertNumberToShortString = (number) => {
  const units = ['', 'K', 'M', 'B', 'T', 'Q'];
  let exponent = 0;
  while (number >= 1000 && exponent < units.length - 1) {
    number /= 1000;
    exponent++;
  }
  return Math.floor(number) + units[exponent];
}

export default convertNumberToShortString
import { memo, useEffect } from 'react'
import styles from './_empty.module.sass'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Button from '../Auth/Button'
import { Trophy } from '../Icons'

const Empty = ({img, text, highlight, goBack, bookmark, signup, upgrade}) => {

  const router = useRouter()

  const ChevronLeft = () => <svg><path d="m14 18-6-6 6-6h0"/></svg>

  useEffect(()=>{
    const windowHeight = window.innerHeight - 180
    const resizeEmptyScreen = () => {
      if(document.getElementById('emptyScreen') && document.getElementById('emptyScreen').style.height !== windowHeight+'px'){
        document.getElementById('emptyScreen').style.height = windowHeight+'px'
      }
    }
    resizeEmptyScreen()
  }, [])

  let routeTimeout = null

  const routeBack = () => {
    clearTimeout(routeTimeout)
    routeTimeout = setTimeout(()=>{
        if(window.navigation.canGoBack){
            router.back()
        }else{
            router.push('/app')
        }
    }, 300)
  }

  const routeTo = (url) => {
    clearTimeout(routeTimeout)
    routeTimeout = setTimeout(()=>{
      router.push(url)
    }, 300)
  }

  const getText = () => {
    if(highlight){
      const regex = new RegExp(`(${highlight})`, 'gi')
      const parts = text.split(regex)
      return parts.filter(part => part).map((part, i) => (
        regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
      ))
    }else{
      return text
    }
  }

  return (
    <div id='emptyScreen' className={styles.empty}>
        <div className={styles.img}><Image src={img} alt='search' sizes='96px' fill priority /></div>
        <p>{getText()}</p>
        {goBack?
        <div className={styles.link} onMouseDown={routeBack}>
          <ChevronLeft />
          <span>Go Back</span>
        </div>
        :null}
        {bookmark?
        <div className={styles.link} onMouseDown={()=>routeTo('/app')}>
          <ChevronLeft />
          <span>Start Browsing</span>
        </div>
        :null}
        {signup?
        <Button text='Get Started' onClick={()=>routeTo('/auth')} colors={['var(--primary)', 'var(--brand3)', 'var(--border)']} />
        :null}
        {upgrade?
        <Button className={styles.button} text='Start 1 Week Free Trial' svg={<Trophy style={{stroke: 'white'}} />} onClick={()=>routeTo('/upgrade')} colors={['var(--brand3)', 'var(--brand3)', 'var(--border)']} />
        :null}
        {upgrade?<div className={styles.subText}>No Credit Card Required</div>:null}
    </div>
  )

}

export default memo(Empty)